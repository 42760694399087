<template>
  <app-layout>
    <div class="not-found">
      <span>Hoppla! Diese Seite wurde nicht gefunden.</span>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from '@/components/AppLayout'

export default {
  name: 'NotFoundComponent',
  components: { AppLayout },
}
</script>

<style lang="scss">
.not-found {
  height: 200px;
  width: 650px;
  margin: 100px auto 0;
  padding: 5px 0;
  border-radius: 3px;
  display: flex;
  font-size: 23px;

  span {
    margin: auto;
  }
}
</style>
